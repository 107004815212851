@import '../../styles/_vars'

.arrow-button 
  display: inline-flex
  vertical-align: middle
  justify-content: center
  align-items: center
  flex-grow: 0
  flex-shrink: 0

  height: 48px
  width: 48px

  border: 0.7px solid rgba(255, 255, 255, 0.5)
  border-radius: 50%

  background: transparent
  transition-duration: .2s

  @media (max-width: $sm)
    width: 35px
    height: 35px

  img
    width: 16px
    height: 16px

  &:hover
    background: $accent-transparent
    cursor: pointer
